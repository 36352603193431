import React, { Component, Fragment } from 'react';
import MaterialTable        from 'material-table';
import Grid                 from '@material-ui/core/Grid';
import Paper                from '@material-ui/core/Paper';
import Typography           from '@material-ui/core/Typography';
import Alert                from '@material-ui/lab/Alert';
import Button               from '@material-ui/core/Button';
import TextField            from '@material-ui/core/TextField';

import SearchIcon           from '@material-ui/icons/Search';

/*
 * Este componente permite al usuario seleccionar distintos
 * criterios de busqueda, realizar consultas a la DB de grabaciones,
 * visualizar los resultados por medio de una tabla dinamica y
 * descargar los archivos de audio.
 *
 * @see https://material-table.com/#/docs/get-started
 * @see https://getbootstrap.com/docs/4.3/components/buttons/
 *
 * @author Victor Villarreal <victorv@gedco.com.ar> para Gedco S.A.
 */
class Grabaciones extends Component {

  constructor(props){
    super(props);

    /*
     * Estados de la aplicacion:
     *
     * registros: Resultados de la busqueda.
     * tel: Numero de telefono ingresado.
     * afterDate: epoch de la fecha 'desde'.
     * beforeDate: epoch de la fecha 'hasta'.
     * loading: Flag para indicar en la tabla, que una operacion esta en curso.
     * canSubmit: Flag que habilita/deshabilita el boton 'Buscar'.
     */
    this.state = {
      registros: [],
      tel: "",
      afterDate: 0,
      beforeDate: this.todayEpoch(),
      loading: false,
      canSubmit: false
    };

    /*
     * Manejadores para los distintos eventos de usuario
     */
    this.handleTelChange = this.handleTelChange.bind(this);
    this.handleAfterDateChange = this.handleAfterDateChange.bind(this);
    this.handleBeforeDateChange = this.handleBeforeDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  /*
   * Tareas a realizar antes que el componente se monte en el DOM
   */
  componentDidMount() {
    //console.log('NODE_ENV', process.env.NODE_ENV);
    this.props.setTitle ? this.props.setTitle('Grabaciones') : console.log('Grabaciones: prop.setTile() no encontrado')
  }

  /*
   * Rutina que se dipara cuando el input de telefono cambia
   */
  handleTelChange(event) {
    this.setState({tel: event.target.value});

    if(event.target.value.length >= 8) this.setState({canSubmit: true});
  }

  /*
   * Rutina que se dipara cuando el input de fecha 'Desde' cambia
   */
  handleAfterDateChange(event) {
    // fecha vacia significa buscar desde el principio
    var myEpoch = 0;

    if(event.target.value !== "") {
      var myDate = new Date(event.target.value);
      myEpoch = Math.round(myDate.getTime() / 1000.0);
    }

    this.setState({afterDate: myEpoch});
  }

  /*
   * Rutina que se dipara cuando el input de fecha 'Hasta' cambia
   */
  handleBeforeDateChange(event) {
    // Fecha vacia significa buscar hasta hoy
    var myEpoch = this.todayEpoch();

    if(event.target.value !== "") {
      var myDate = new Date(event.target.value);
      myEpoch = Math.round(myDate.getTime() / 1000.0) + 86390;
    }

    this.setState({beforeDate: myEpoch});
  }

  /*
   * Rutina que se dipara cuando se presiona el boton 'Buscar'
   */
  handleSubmit(event) {
    this.setState({loading: true, canSubmit: false});
    this.findGrabacionesByTel(this.state.tel);
    event.preventDefault();
  }

  /*
   * Devuelvo la marca epoch de este instante
   *
   * @return epoch int
   */
  todayEpoch() {
    var hoy = new Date();
    var epochHoy = Math.round(hoy.getTime() / 1000);

    return epochHoy;
  }

  /*
   * Solicita una URL de descarga para un audio dado,
   * y luego redirige el navegador a dicha direccion.
   *
   * @param objectKey string Nombre del objecto que se desea descargar desde S3
   * @return null
   */
  handleDownload(objectKey) {
    var url = "https://api.gedco.com.ar/grabaciones-dev/signedUrl?key=";
    this.setState({loading: true});

    if(process.env.REACT_APP_STAGE === 'production') {
      url = "https://api.gedco.com.ar/grabaciones/signedUrl?key=";
    }

    // Primero solicito una URL firmada, valida por 15 minutos...
    fetch(url + objectKey , {
      method: "GET"
    }).then(response => response.json()).then(signedUrl => {

      // Luego con esa URL, descargo finalmente el archivo.
      // Gracias a https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      fetch(signedUrl.url , {
        method: "GET"
      }).then(sUrl => sUrl.blob()).then(theBlob => {
        const url = window.URL.createObjectURL(theBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', objectKey);
        document.body.appendChild(link);
        this.setState({loading: false});
        link.click();
      });
    });

  }

  /*
   * Envia peticion a la API para buscar un audio. Envia numero de telefono,
   * fecha de inicio y fin.
   *
   * @param tel string numero de telefono
   */
  findGrabacionesByTel(tel){
    var url = "https://7szf019c24.execute-api.us-east-2.amazonaws.com/dev/grabaciones?tel=" + tel;

    if(process.env.REACT_APP_STAGE === 'production') {
      url = "https://f2bf18l2ce.execute-api.us-east-2.amazonaws.com/prod/grabaciones?tel=" + tel;
    }

    // Algunos controles basicos antes de buscar...
    if (this.state.afterDate >= this.state.beforeDate) {
      window.alert("La fecha de inicio no puede ser mayor o igual a la de fin");
      return null;
    }

    // Concateno los parametros con las fechas de inicio y fin
    url += "&afterDate=" + this.state.afterDate + "&beforeDate=" + this.state.beforeDate;

    fetch(url, {
      method: "GET"
    }).then(response => response.json()).then(registros => {
      this.setState({registros: registros, loading: false});
      //console.log(this.state.registros);
    });
  }

  /*
   * Rutina que printea el epoch que viene en el JSON
   * de la respuesta, a formato humano, en cada una
   * de las filas de la data-table.
   *
   * @param epoch int Marca epoch a formatear
   */
  toDateTime(epoch) {
    var dateTime = new Date(epoch * 1000);
    return dateTime.toISOString().replace("T", " ").replace(".000Z", "");
  }

  /*
   * Render() function
   */
  render() {

    /*
     * Propiedades de las columnas para la data-table
     */
    const columns = [
      {
        title: 'Fecha/Hora',
        field: 'fecha',
        render: rowData => this.toDateTime(rowData.fecha)
      },
      {
        title: 'Pais',
        field: 'pais',
      },
      {
        title: 'Telefono',
        field: 'telefono',
      },
      {
        title: 'Operador',
        field: 'operador',
      }
    ]

    /*
     * Traduccion al castellano para la data-table
     */
    const localization = {
      header: {
        actions: 'Acciones'
      },
      pagination: {
        labelDisplayedRows: '{from}-{to} de {count}',
        labelRowsSelect: 'registros'
      },
      toolbar: {
        nRowsSelected: '{0} row(s) selected',
        searchTooltip: 'Buscar',
        searchPlaceholder: 'Buscar'
      },
      body: {
        emptyDataSourceMessage: 'Ningún resultado para mostrar',
      }
    }

    /*
     * Return() function
     */
    return (
      <Fragment>

        <Alert severity="success" variant="outlined">
          Info: Revise <a href="https://bit.ly/gedco-teldash-importstatus">esta planilla</a> para conocer el estado actual del proceso de importaci&oacute;n.
        </Alert>

        <Paper style={{marginTop: 20, padding: 10}}>
          <Typography variant="h5">
            B&uacute;squeda de grabaciones
          </Typography>

          <Typography variant="body2">
            Seleccione los criterios de b&uacute;squeda,
            visualice los resultados en la tabla y descargue los archivos de su inter&eacute;s.
          </Typography>

          <form onSubmit={this.handleSubmit} style={{ marginTop: 20 }}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={4}>
            <TextField
              type="number"
              required
              id="tel"
              label="Tel&eacute;fono"
              onChange={this.handleTelChange}
              value={this.state.value}
              InputLabelProps={{shrink: true}}
              margin="normal"
              fullWidth
            />
            </Grid>

            <Grid item xs={12} sm={4}>
            <TextField
              type="date"
              id="after-date"
              label="Desde"
              onChange={this.handleAfterDateChange}
              InputLabelProps={{shrink: true}}
              margin="normal"
              fullWidth
            />
            </Grid>

            <Grid item xs={12} sm={4}>
            <TextField
              type="date"
              id="before-date"
              label="Hasta"
              onChange={this.handleBeforeDateChange}
              InputLabelProps={{shrink: true}}
              margin="normal"
              fullWidth
            />
            </Grid>

            <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
              onClick={this.handleSubmit}
              disabled={!this.state.canSubmit}
              size="large"
              fullWidth
            >
              Buscar
            </Button>
            </Grid>

          </Grid>
          </form>

        </Paper>

        <MaterialTable
          isLoading={this.state.loading}
          columns={columns}
          data={this.state.registros}
          title="Resultados"
          localization={localization}
          style={{ marginTop: 20 }}
          actions={[
            {
              icon: 'save',
              tooltip: 'Descargar audio',
              onClick: (event, rowData) => this.handleDownload(rowData.path)
            }
          ]}
          options={{
            exportButton: true
          }}
        />
      </Fragment>
    );
  }
}

export default Grabaciones;
