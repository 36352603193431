import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

// Importamos componentes locales
import Menu from './Menu';
import Footer from './Footer';

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  handleToggle = () => this.setState({open: !this.state.open});

  render() {
    const { classes, variant, open, onClose } = this.props;

    return (
      <Drawer
        className={classes.drawer}
        variant={variant}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
        open={open}
        onClose={onClose ? onClose : null}
      >

        <Footer />
        <Divider />
        <Menu />

      </Drawer>
    )
  }
}


const styles = theme => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  toolbar: theme.mixins.toolbar,
});


export default withStyles(styles)(Sidebar);
