import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';

class Navbar extends Component {
    constructor(props){
        super(props);
        this.state = {
            fullName: ""
        };
        this.handleLogoff = this.handleLogoff.bind(this);
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => this.setState({fullName: user.attributes.name}))
        .catch(err => console.log(err));
    }

    handleLogoff(event) {
        event.preventDefault();
        Auth.signOut({ global: true })
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

  render() {
    const title = this.props.title || 'Gedco Tel Dashboard';
    const { classes, toogleDrawer } = this.props;

    return (
      <AppBar position="fixed" className={classes.appBar}>

        <Toolbar>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={toogleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            style={{flexGrow: 1}}
          >
            {title}
          </Typography>

          <Button
            color="inherit"
            endIcon={<ExitToAppIcon />}
            onClick={this.handleLogoff}
          >
            {this.state.fullName}
          </Button>

        </Toolbar>

      </AppBar>
    );
  }
}


const styles = theme => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 240px)`,
      marginLeft: 240,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
});


export default withStyles(styles)(Navbar);
