import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

class Footer extends Component {

  render() {
    const app_version = process.env.REACT_APP_VERSION;
    const { classes } = this.props;

    return (
      <div className={classes.toolbar}>
        <Link
          color="inherit"
          href="/"
          variant="h6">

          Gedco Tel-Dash

        </Link>

        <Link
          href="https://bit.ly/gedco-teldash-changelog"
          variant="caption">

          version {app_version}

        </Link>

      </div>
    );
  }
}

const styles = theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});


export default withStyles(styles)(Footer);
