// Importamos librerias instaladas
import React, { Component } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/indigo';
import secondary from '@material-ui/core/colors/pink';
import { withAuthenticator } from 'aws-amplify-react';
import { I18n } from 'aws-amplify';
import Amplify from 'aws-amplify';

// Importamos componentes locales
import DrawerContainer from './components/DrawerContainer';


Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_IOjig2xKI',

    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '27sie2teopj1ttouu2um4enqgt',
  }
});


const dict = {
  'es': {
    'Sign In': "Ingresar",
    'Sign Up': "Registrarse",
    'Sign in to your account': "Inicie sesion en su cuenta",
    'Enter your username': "Ingrese su usuario",
    'Enter your password': "Ingrese su contraseña",
    'Forget your password? ': "Olvidaste tu contraseña? ",
    'Reset password': "Resetear clave",
    'Reset your password': "Reinicie su contraseña",
    'No account?': "¿No tienes una cuenta?"
  }
};


I18n.setLanguage('es');
I18n.putVocabularies(dict);


class App extends Component {
  render() {
    const dashTheme = createMuiTheme({
      palette: {
        type: 'light',
        primary: primary,
        secondary: secondary
      },
    });

    return (
      <ThemeProvider theme={dashTheme}>

        <DrawerContainer />

      </ThemeProvider>
    );
  }
}


export default withAuthenticator(App);
