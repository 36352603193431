import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import MicIcon from '@material-ui/icons/Mic';

class Menu extends Component {

  render() {

    return (
      <List component="nav" aria-label="main menu">

        <ListItem button>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <MicIcon />
          </ListItemIcon>
          <ListItemText primary="Grabaciones" />
        </ListItem>

      </List>
    );
  }
}

export default Menu;
