import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

// Importamos componentes locales
import Navbar from './Navbar.js';
import Sidebar from './Sidebar.js';
import Grabaciones from './Grabaciones.js';


class DrawerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false, title: 'Inicio'};
  }

  toogleDrawer = () => this.setState({open: !this.state.open});
  setTitle = (title) => this.setState({title: title});

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>

        <Navbar
          toogleDrawer={this.toogleDrawer}
          title={this.state.title}
        />

        <Hidden smDown>
          <Sidebar
            variant="permanent"
            open={true}
          />
        </Hidden>

        <Hidden mdUp>
          <Sidebar
            variant="temporary"
            open={this.state.open}
            onClose={this.toogleDrawer}
          />
        </Hidden>

        <div className={classes.content}>

          <div className={classes.toolbar}></div>
          <Grabaciones setTitle={this.setTitle} />

        </div>

      </div>
    );
  }
}


const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
});


export default withStyles(styles)(DrawerContainer);
