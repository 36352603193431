/*
 * @see https://facebook.github.io/create-react-app/docs/getting-started
 * @see https://reactjs.org/docs/create-a-new-react-app.html
 * @see https://code.visualstudio.com/docs/nodejs/reactjs-tutorial
 * @see https://mherman.org/blog/dockerizing-a-react-app/
 * @see https://www.youtube.com/watch?v=91F8reC8kvo
 * @see https://themes-pixeden.com/font-demos/7-stroke/
 * @see https://serverless-stack.com/chapters/create-containers.html
 */

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({dsn: "https://e4611332531245d4a934187882510e0a@o258254.ingest.sentry.io/1452397"});

ReactDOM.render(<App />, document.getElementById('root'));
